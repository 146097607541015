// btn
.btn {
	padding: 0.5rem 1rem;
	font-size: 20px;
	text-transform: uppercase;

	// btn-primary
	&.btn-primary {
		color: $white;

		&:hover {
			background-color: lighten($primary, 15%);
			border-color: lighten($primary, 15%);
			color: $white;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
			color: $white;
		}
	}

	// btn-secondary
	&.btn-secondary {
		color: $white;

		&:hover {
			background-color: lighten($secondary, 15%);
			border-color: lighten($secondary, 15%);
			color: $white;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
			color: $white;
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	background-color: $cyan;
	border-color: $cyan;
	color: $white;

	&:hover {
		background-color: $green;
		border-color: $green;
		color: $white;
	}
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
