// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	padding: 50px 0 15px 0;
	background: $white;
	transition: 0.5s;

	@include media-breakpoint-down(lg) {
		padding-top: 25px;
	}

	@include media-breakpoint-up(xl) {
		padding-left: 45px;
		padding-right: 45px;
	}

	&.sticky {
		padding-top: 15px;
		box-shadow: $shadow;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;
				justify-content: flex-end;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin: 0 0 0 auto;

		.navbar-toggler {
			display: flex;
			align-items: center;
			height: 100%;
			padding: 0.2rem 0.6rem;
			border: 4px solid $white;
			border-radius: 10px;
			background-color: $red;
			box-shadow: 3px 3px 0 0 rgba($black, 0.15);
			color: $white;
			font-size: 20px;
			text-transform: uppercase;
			transition: 0.5s;

			.navbar-toggler-icon {
				width: 30px;
				line-height: 30px;
				text-align: center;
			}

			.navbar-toggler-label {
				margin-left: 3px;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				background-color: $cyan;
			}
		}
	}

	// logo
	.logo {
		z-index: 100;
		position: absolute;
		top: 40px;
		left: 15px;
		width: 175px;
		transition: width 0.5s;

		@include media-breakpoint-down(lg) {
			top: -15px;
		}

		@include media-breakpoint-up(xl) {
			width: 300px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				padding-top: 45px;

				>.nav-item {
					margin-bottom: 8px;

					>.nav-link {
						display: flex;
						align-items: center;
						padding: 0.5rem 15px;
						background-color: $blue;
						border-radius: 0.25rem;
						color: $white;
						font-size: 18px;
						font-weight: 400;
						text-transform: uppercase;

						&:hover {
							background-color: $blue-dark;
							box-shadow: 4px 4px 0 0 rgba($black, 0.1);
							color: $white;
						}
					}

					&.active {
						>.nav-link {
							background-color: $blue-dark;
							box-shadow: 4px 4px 0 0 rgba($black, 0.1);
							color: $white;
						}
					}

					&:nth-child(1) {
						>.nav-link {
							background-color: $blue;

							&:hover {
								background-color: $blue-dark;
							}
						}

						&.active {
							>.nav-link {
								background-color: $blue-dark;
							}
						}
					}

					&:nth-child(2) {
						>.nav-link {
							background-color: $green;

							&:hover {
								background-color: $green-dark;
							}
						}

						&.active {
							>.nav-link {
								background-color: $green-dark;
							}
						}
					}

					&:nth-child(3) {
						>.nav-link {
							background-color: $orange;

							&:hover {
								background-color: $orange-dark;
							}
						}

						&.active {
							>.nav-link {
								background-color: $orange-dark;
							}
						}
					}

					&:nth-child(4) {
						>.nav-link {
							background-color: $yellow;

							&:hover {
								background-color: $yellow-dark;
							}
						}

						&.active {
							>.nav-link {
								background-color: $yellow-dark;
							}
						}
					}

					&:nth-child(5) {
						>.nav-link {
							background-color: $purple;

							&:hover {
								background-color: $purple-dark;
							}
						}

						&.active {
							>.nav-link {
								background-color: $purple-dark;
							}
						}
					}

					&:nth-child(6) {
						>.nav-link {
							background-color: $pink;

							&:hover {
								background-color: $pink-dark;
							}
						}

						&.active {
							>.nav-link {
								background-color: $pink-dark;
							}
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 5px 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 16px;

							&.first {
								font-size: 18px;
							}

							.nav-link {
								color: $gray-light;

								&:hover {
									color: $cyan;
								}
							}

							&.active {
								>.nav-link {
									color: $cyan;
								}
							}
						}
					}

					&:nth-child(1) {
						.dropdown-menu {
							.nav-item {
								&.first {
									>.nav-link {
										color: $blue;

										&:hover {
											color: $blue-dark;
										}
									}

									&.active {
										>.nav-link {
											color: $blue-dark;
										}
									}
								}
							}
						}
					}

					&:nth-child(2) {
						.dropdown-menu {
							.nav-item {
								&.first {
									>.nav-link {
										color: $green;

										&:hover {
											color: $green-dark;
										}
									}

									&.active {
										>.nav-link {
											color: $green-dark;
										}
									}
								}
							}
						}
					}

					&:nth-child(3) {
						.dropdown-menu {
							.nav-item {
								&.first {
									>.nav-link {
										color: $orange;

										&:hover {
											color: $orange-dark;
										}
									}

									&.active {
										>.nav-link {
											color: $orange-dark;
										}
									}
								}
							}
						}
					}

					&:nth-child(4) {
						.dropdown-menu {
							.nav-item {
								&.first {
									>.nav-link {
										color: $yellow;

										&:hover {
											color: $yellow-dark;
										}
									}

									&.active {
										>.nav-link {
											color: $yellow-dark;
										}
									}
								}
							}
						}
					}

					&:nth-child(5) {
						.dropdown-menu {
							.nav-item {
								&.first {
									>.nav-link {
										color: $purple;

										&:hover {
											color: $purple-dark;
										}
									}

									&.active {
										>.nav-link {
											color: $purple-dark;
										}
									}
								}
							}
						}
					}

					&:nth-child(6) {
						.dropdown-menu {
							.nav-item {
								&.first {
									>.nav-link {
										color: $pink;

										&:hover {
											color: $pink-dark;
										}
									}

									&.active {
										>.nav-link {
											color: $pink-dark;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			flex-grow: 0;

			.navbar-nav {
				>.nav-item {
					display: flex;
					align-items: center;
					margin-left: 8px;
					text-transform: uppercase;

					>.nav-link {
						padding: 3px 15px;
						background-color: $blue;
						border-radius: 0.25rem;
						color: $white;
						font-weight: 400;

						&:hover {
							background-color: $blue-dark;
							box-shadow: 4px 4px 0 0 rgba($black, 0.1);
							color: $white;
						}
					}

					&.active {
						>.nav-link {
							background-color: $blue-dark;
							box-shadow: 4px 4px 0 0 rgba($black, 0.1);
							color: $white;
						}
					}

					&:nth-child(1) {
						>.nav-link {
							background-color: $blue;

							&:hover {
								background-color: $blue-dark;
							}
						}

						&.active {
							>.nav-link {
								background-color: $blue-dark;
							}
						}
					}

					&:nth-child(2) {
						>.nav-link {
							background-color: $green;

							&:hover {
								background-color: $green-dark;
							}
						}

						&.active {
							>.nav-link {
								background-color: $green-dark;
							}
						}
					}

					&:nth-child(3) {
						>.nav-link {
							background-color: $orange;

							&:hover {
								background-color: $orange-dark;
							}
						}

						&.active {
							>.nav-link {
								background-color: $orange-dark;
							}
						}
					}

					&:nth-child(4) {
						>.nav-link {
							background-color: $yellow;

							&:hover {
								background-color: $yellow-dark;
							}
						}

						&.active {
							>.nav-link {
								background-color: $yellow-dark;
							}
						}
					}

					&:nth-child(5) {
						>.nav-link {
							background-color: $purple;

							&:hover {
								background-color: $purple-dark;
							}
						}

						&.active {
							>.nav-link {
								background-color: $purple-dark;
							}
						}
					}

					&:nth-child(6) {
						>.nav-link {
							background-color: $pink;

							&:hover {
								background-color: $pink-dark;
							}
						}

						&.active {
							>.nav-link {
								background-color: $pink-dark;
							}
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 1rem;

						.nav-item {
							font-size: 16px;
							font-weight: 400;
							line-height: 1;
							white-space: nowrap;

							&.first {
								font-size: 18px;
							}

							.nav-link {
								padding: 0.4rem 0.5rem;
								color: $gray-light;

								&:hover {
									color: $cyan;
								}
							}

							&.active {
								.nav-link {
									color: $cyan;
								}
							}
						}
					}

					&:nth-child(1) {
						.dropdown-menu {
							.nav-item {
								&.first {
									>.nav-link {
										color: $blue;

										&:hover {
											color: $blue-dark;
										}
									}

									&.active {
										>.nav-link {
											color: $blue-dark;
										}
									}
								}
							}
						}
					}

					&:nth-child(2) {
						.dropdown-menu {
							.nav-item {
								&.first {
									>.nav-link {
										color: $green;

										&:hover {
											color: $green-dark;
										}
									}

									&.active {
										>.nav-link {
											color: $green-dark;
										}
									}
								}
							}
						}
					}

					&:nth-child(3) {
						.dropdown-menu {
							.nav-item {
								&.first {
									>.nav-link {
										color: $orange;

										&:hover {
											color: $orange-dark;
										}
									}

									&.active {
										>.nav-link {
											color: $orange-dark;
										}
									}
								}
							}
						}
					}

					&:nth-child(4) {
						.dropdown-menu {
							.nav-item {
								&.first {
									>.nav-link {
										color: $yellow;

										&:hover {
											color: $yellow-dark;
										}
									}

									&.active {
										>.nav-link {
											color: $yellow-dark;
										}
									}
								}
							}
						}
					}

					&:nth-child(5) {
						.dropdown-menu {
							.nav-item {
								&.first {
									>.nav-link {
										color: $purple;

										&:hover {
											color: $purple-dark;
										}
									}

									&.active {
										>.nav-link {
											color: $purple-dark;
										}
									}
								}
							}
						}
					}

					&:nth-child(6) {
						.dropdown-menu {
							.nav-item {
								&.first {
									>.nav-link {
										color: $pink;

										&:hover {
											color: $pink-dark;
										}
									}

									&.active {
										>.nav-link {
											color: $pink-dark;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// searchbox
	.searchbox {
		margin-left: 5px;

		@include media-breakpoint-up(sm) {
			margin-left: 15px;
		}

		form {
			position: relative;

			.form-control {
				height: 31px;
				background: rgba($black, 0.05);
				border-color: rgba($black, 0.01);

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}

			.search-icon {
				@include media-breakpoint-up(md) {
					position: absolute;
					top: 1px;
					right: 1px;
					color: rgba($black, 0.25);
					font-size: 20px;
				}

				width: 29px;
				height: 29px;
				border: none;
				background: none;
				color: $gray-dark;
				font-size: 24px;
				line-height: 29px;
				text-align: center;
				cursor: pointer;
				transition: 0.5s;

				&:hover {
					color: $cyan;
				}
			}
		}
	}
}

&.sticky,
&.navbar-collapse-active {
	.header {
		.logo {
			width: 150px;
		}
	}
}
