& {
	font-size: 20px;
	letter-spacing: -1px;
}

// container
.container {
	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

.container-two-columns {
	.container-holder {
		align-items: center;
	}
}

// container-fluid
.container-fluid {
	max-width: 1920px;
}

// headings
h1,
h2,
h3,
h4,
h5,
h6 {
	text-transform: uppercase;
}

h1 {
	color: $green-darker;
}

h2 {
	color: $green;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

&:not(.home) {

	// card
	.card:not(.extra) {
		background-color: rgba($blue, 0.2);
		border-radius: 15px;
		border: none;
		transition: background-color 0.5s;

		.card-image {
			border-radius: 15px 15px 0 0;
		}

		.card-body {
			padding: 25px;

			.card-caption {
				text-align: center;

				.card-title {
					display: inline-flex;
					align-items: center;
					margin-bottom: 0;
					color: $cyan;

					&:hover {
						color: $blue;
					}
				}

				.card-subtitle {
					@include font-size($h5-font-size);
				}

				.card-description {
					p {
						margin-bottom: 0;
					}
				}
			}

			.card-buttons {
				text-align: center;
			}
		}
	}

	.collection-section.bg-dark {
		.card {
			background-color: $purple-light !important;

			.card-title {
				color: $purple !important;

				&:hover {
					color: darken($purple, 25%) !important;
				}
			}

			.card-btn {
				border-color: $purple !important;
				background-color: $purple !important;

				&:hover {
					border-color: darken($purple, 25%) !important;
					background-color: darken($purple, 25%) !important;
				}
			}
		}
	}
}

// card > extra
.card.extra {
	background-color: $blue;
	border-radius: 15px 15px 0 0;
	border: none;
	transition: background-color 0.5s;

	&:hover {
		background-color: $cyan;
	}

	.card-image {
		border-radius: 15px 15px 0 0;
	}

	.card-body {
		padding: 10px 15px;

		.card-caption {
			color: $white;
			text-align: center;

			.card-title {
				margin-bottom: 0;
				color: $white;

				&:hover {
					color: $white;
				}
			}

			.card-subtitle {
				@include font-size($h5-font-size);
			}
		}
	}
}

// quote
.quote {
	font-family: $font-family-secondary;
	@include font-size($h3-font-size);

	@include media-breakpoint-up(xl) {
		font-size: 36px;
	}
}

// youtube-video
.youtube-video {
	position: relative;
	overflow: hidden;
	width: 100%;
	margin-bottom: 1rem;
	padding-top: 56.25%;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		border-radius: 15px;
		border: 5px solid $blue;
	}
}
