// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 40px 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-40px + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 40px 0;

	@include media-breakpoint-up(xl) {
		font-size: 22px;
	}

	.container-one-column {
		.container-holder {
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 40px 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 40px 0;
}

// home-collection-section
// =========================================================================
.home-collection-section {
	margin: 40px 0;

	// collection
	.collection {
		.card {
			background-color: $blue;
			border-radius: 15px 15px 0 0;
			border: none;
			transition: background-color 0.5s;

			&:hover {
				background-color: $cyan;
			}

			.card-image {
				border-radius: 15px 15px 0 0;
			}

			.card-body {
				padding: 10px 15px;

				.card-caption {
					color: $white;
					text-align: center;

					.card-title {
						display: inline-flex;
						align-items: center;
						margin-bottom: 0;
						color: $white;

						&::after {
							content: "";
							display: inline-block;
							width: 20.5px;
							height: 15px;
							background-image: url('/images/arrow-white.png');
							background-size: 20.5px 15px;
							margin-left: 10px;
							transition: 0.5s;
						}

						&:hover {
							&::after {
								transform: translateX(5px);
							}
						}
					}

					.card-subtitle {
						@include font-size($h5-font-size);
					}

					.card-description {
						display: none;
					}
				}

				.card-buttons {
					text-align: center;
				}
			}
		}
	}
}

// featured-section
// =========================================================================
.featured-section {
	margin: 40px 0;
	padding: 40px 0;
	background-color: $green;
	color: $white;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}

	a {
		color: $white;
	}

	img {
		border-radius: 15px;
		border: 5px solid $white;
	}

	.container-holder {
		.column {
			&.one {
				padding-right: 15px !important;
			}

			&.two {
				padding-left: 15px !important;
			}

			&.column-wide {
				@include media-breakpoint-up(lg) {
					@include make-col(8);
				}
			}

			&.column-narrow {
				@include media-breakpoint-up(lg) {
					@include make-col(4);
				}
			}
		}
	}
}

// video-section
// =========================================================================
.video-section {
	margin: 40px 0;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $blue;
	}

	.container-holder {
		margin: 0;
		padding: 30px 15px 15px 15px;
		border-radius: 15px;
		background-color: rgba($blue, 0.2);

		.column {
			&.one {
				padding-right: 15px !important;
			}

			&.two {
				padding-left: 15px !important;
			}

			&.column-wide {
				@include media-breakpoint-up(md) {
					@include make-col(8);
				}
			}

			&.column-narrow {
				@include media-breakpoint-up(md) {
					@include make-col(4);
				}
			}
		}
	}
}

// figure-section
// =========================================================================
.figure-section {
	margin: 10px 0;
	text-align: center;

	.container-holder {
		margin: 0;
		border-radius: 30px;
		padding: 30px 15px;

		@include media-breakpoint-up(xl) {
			margin-left: 0;
			margin-right: 0;
			padding-left: 30px;
			padding-right: 30px;
		}

		@include media-breakpoint-down(sm) {
			img {
				max-width: 240px;
			}
		}

		p:last-of-type {
			margin-bottom: 0;
		}

		.column {
			margin-bottom: 0;

			&.one {
				padding-right: 15px !important;

				@include media-breakpoint-up(md) {
					@include make-col(4);
				}

			}

			&.two {
				padding-left: 15px !important;

				@include media-breakpoint-up(md) {
					@include make-col(8);
				}
			}
		}
	}

	&:nth-child(odd) {
		.container-holder {
			.column {
				&.one {
					padding-right: 15px !important;

					@include media-breakpoint-up(md) {
						@include make-col(8);
					}
				}

				&.two {
					padding-left: 15px !important;

					@include media-breakpoint-down(sm) {
						order: -1;
						margin-bottom: 30px;
					}

					@include media-breakpoint-up(md) {
						@include make-col(4);
					}
				}
			}
		}
	}

	&:nth-child(even) {
		.container-holder {
			.column {
				&.one {
					@include media-breakpoint-down(sm) {
						margin-bottom: 30px;
					}
				}
			}
		}
	}

	&:nth-child(2) {
		.container-holder {
			background-color: rgba($green, 0.35);
		}

		h2,
		.quote {
			color: $green-dark;
		}

		.btn {
			background-color: $green-dark;
			border-color: $green-dark;

			&:hover {
				background-color: darken($green-dark, 15%);
				border-color: darken($green-dark, 15%);
			}
		}
	}

	&:nth-child(3) {
		.container-holder {
			background-color: rgba($purple, 0.35);
		}

		h2,
		.quote {
			color: $purple-dark;
		}

		.btn {
			background-color: $purple-dark;
			border-color: $purple-dark;

			&:hover {
				background-color: darken($purple-dark, 15%);
				border-color: darken($purple-dark, 15%);
			}
		}
	}

	&:nth-child(4) {
		.container-holder {
			background-color: rgba($orange, 0.35);
		}

		h2,
		.quote {
			color: $orange-dark;
		}

		.btn {
			background-color: $orange-dark;
			border-color: $orange-dark;

			&:hover {
				background-color: darken($orange-dark, 15%);
				border-color: darken($orange-dark, 15%);
			}
		}
	}

	&:nth-child(5) {
		.container-holder {
			background-color: rgba($pink, 0.35);
		}

		h2,
		.quote {
			color: $pink-dark;
		}

		.btn {
			background-color: $pink-dark;
			border-color: $pink-dark;

			&:hover {
				background-color: darken($pink-dark, 15%);
				border-color: darken($pink-dark, 15%);
			}
		}
	}

	&:nth-child(6) {
		.container-holder {
			background-color: rgba($yellow, 0.27);
		}

		h2,
		.quote {
			color: $yellow;
		}

		.btn {
			background-color: $yellow;
			border-color: $yellow;

			&:hover {
				background-color: darken($yellow-dark, 15%);
				border-color: darken($yellow-dark, 15%);
			}
		}
	}

	&:nth-child(7) {
		.container-holder {
			background-color: rgba($blue, 0.35);
		}

		h2,
		.quote {
			color: $blue-dark;
		}

		.btn {
			background-color: $blue-dark;
			border-color: $blue-dark;

			&:hover {
				background-color: darken($blue-dark, 15%);
				border-color: darken($blue-dark, 15%);
			}
		}
	}

	&:nth-child(8) {
		.container-holder {
			background-color: rgba($red, 0.35);
		}

		h2,
		.quote {
			color: $red;
		}

		.btn {
			background-color: $red;
			border-color: $red;

			&:hover {
				background-color: darken($red, 15%);
				border-color: darken($red, 15%);
			}
		}
	}

	&:nth-child(9) {
		.container-holder {
			background-color: rgba($green, 0.35);
		}

		h2,
		.quote {
			color: $green-dark;
		}

		.btn {
			background-color: $green-dark;
			border-color: $green-dark;

			&:hover {
				background-color: darken($green-dark, 15%);
				border-color: darken($green-dark, 15%);
			}
		}
	}
}

// content-blocks-section
// =========================================================================
.content-blocks-section {
	margin: 40px 0;

	&:nth-child(odd) {
		.container-holder {
			.column {
				&.two {
					@include media-breakpoint-down(sm) {
						order: -1;
					}
				}
			}
		}
	}
}

// newsletter-section
// =========================================================================
.newsletter-section {
	margin: 40px 0;
	text-align: center;
	color: $blue;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $blue;
	}

	// hbspt-form
	.hbspt-form {
		display: flex;
		justify-content: center;

		fieldset {
			margin-bottom: 10px;
		}

		ul {
			padding-left: 0;

			li {
				list-style: none;
			}
		}

		.hs_firstname,
		.hs_lastname,
		.hs_email {
			text-align: left;

			input {
				@extend .form-control;

				@media (max-width: 400px) {
					width: 100% !important;
				}
			}
		}

		.hs_firstname {
			@media (max-width: 400px) {
				margin-bottom: 10px;
			}
		}

		.hs-dependent-field {
			.input {
				text-align: left;

				.hs-form-booleancheckbox {
					label {
						display: flex;
						align-items: center;

						span {
							margin-left: 10px;
						}
					}
				}
			}
		}

		.hs-richtext {
			font-size: 16px;

			p {
				margin-bottom: 0;
			}
		}

		.hs-submit {
			input {
				@extend .btn;
				@extend .btn-primary;
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 40px 0;
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 40px 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-40px + 30px);
	margin-bottom: calc(-40px + 30px);
}

// contact-section
// =========================================================================
.contact-section {
	margin: 40px 0;
}
