// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$blue: #63c6cb;
$blue-dark: #6fbcc8;

$green: #7fc85f;
$green-dark: #7eb566;
$green-darker: #4e9a15;

$orange: #eb9b38;
$orange-dark: #e99f3d;

$yellow: #f7d03e;
$yellow-dark: #debb37;

$purple: #917bc8;
$purple-light: #d9d1ec;
$purple-dark: #8b7db3;

$pink: #e57baa;
$pink-dark: #de7da4;

$cyan: #349398;
$red: #e7343b;

$gray: #e9e9e9;
$gray-light: #eeeeee;
$gray-dark: #959595;

$white: #fff;
$gray-light: #5c5c5c;
$black: #000;

// theme-colors
$primary: $cyan;
$secondary: $green;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@font-face {
    font-family: 'Mr Dodo Rounded';
    src: url('/webfonts/MrDodoRounded-Light.woff2') format('woff2'),
		 url('/webfonts/MrDodoRounded-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Mr Dodo Rounded';
    src: url('/webfonts/MrDodoRounded-Regular.woff2') format('woff2'),
		 url('/webfonts/MrDodoRounded-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mr Dodo Rounded';
    src: url('/webfonts/MrDodoRounded-Medium.woff2') format('woff2'),
         url('/webfonts/MrDodoRounded-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mr Dodo Rounded';
    src: url('/webfonts/MrDodoRounded-Bold.woff2') format('woff2'),
         url('/webfonts/MrDodoRounded-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Woezel en Pip';
    src: url('/webfonts/woezelenpip-regular-webfont.woff2') format('woff2'),
         url('/webfonts/woezelenpip-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

$font-family-primary: 'Mr Dodo Rounded';
$font-family-secondary: 'Woezel en Pip';
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px
$font-weight-base: 300;
$line-height-base: 1.25;

$h1-font-size: $font-size-base * 2.75; // 44px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 2; // 32px
$h4-font-size: $font-size-base * 1.75; // 28px
$h5-font-size: $font-size-base * 1.5; // 24px
$h6-font-size: $font-size-base * 1.25; // 20px

$headings-margin-bottom: 1rem;
$headings-font-family: $font-family-primary;
$headings-font-weight: 400;
$headings-line-height: 1;
$headings-color: $green;

$paragraph-margin-bottom: 1.5rem;

// contextual
$light: rgba($blue, 0.2);
$dark: $green;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
