// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	max-height: 540px;
}

// eyecatcher
.eyecatcher {
	background: $blue;

	.owl-carousel {
		.item {
			justify-content: center;
		}
	}

	// owl-dots
	.owl-dots {
		left: auto;
		right: 15px;
	}
}
