// footer
.footer {
	margin-top: 40px;

	// footer-outro
	.footer-outro {
		margin: 40px;
		text-align: center;
		color: $blue;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 0;
			color: $blue;
		}
	}

	// footer-sitelinks
	.footer-sitelinks {
		padding: 40px 0 6vh 0;
		background-color: $blue;
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		a {
			color: $white;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		// footer-logolink
		.footer-logolink {
			.list {
				.list-item {
					margin-right: 10px;

					a {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 36px;
						height: 36px;
						background-color: $white;
						border-radius: 100%;
						color: $blue;
						font-size: 22px;
						text-decoration: none;

						&:hover {
							background-color: $cyan;
							color: $white;
						}
					}

					&:first-of-type {
						a {
							background-color: transparent;
							color: $white;
							font-size: 36px;

							&:hover {
								color: $cyan;
							}
						}
					}
				}
			}
		}

		// container-four-columns
		.container-four-columns {
			.container-holder {
				margin-bottom: -30px;

				>.column {
					margin-bottom: 30px;
				}
			}
		}

		// hbspt-form
		.hbspt-form {

			.form-columns-2 {
				.hs-form-field {
					width: 100%;
					float: none;
				}
			}

			fieldset {
				margin-bottom: 10px;
			}

			ul {
				padding-left: 0;

				li {
					list-style: none;
				}
			}

			.hs_firstname,
			.hs_lastname,
			.hs_email {
				text-align: left;

				input {
					@extend .form-control;

					@media (max-width: 400px) {
						width: 100% !important;
					}
				}
			}

			.hs_firstname {
				margin-bottom: 10px;
			}

			.hs-dependent-field {
				.input {
					text-align: left;

					.hs-form-booleancheckbox {
						label {
							display: flex;
							align-items: center;

							span {
								margin-left: 10px;
							}
						}
					}
				}
			}

			.hs-richtext {
				font-size: 16px;

				p {
					margin-bottom: 0;
				}
			}

			.hs-submit {
				input {
					@extend .btn;
					@extend .btn-primary;
				}
			}
		}
	}
}

&.home {
	.footer {
		margin-top: 40px;
	}
}
