&.search {
	.content-section {
		.page-block {
			&.searchbox {
				margin: 0 0 15px 0;

				.form-control {
					border-color: $black;

					&:focus {
						border-color: $black;
						outline: 0;
						box-shadow: 0 0 0 0.2rem rgba($green, .25);
					}
				}

				.search-icon {
					display: none;
				}
			}

			&.search-results {
				.search_term {
					margin-bottom: 30px;

					h3 {
						font-size: 20px;
					}
				}

				.search_alert {
					margin-bottom: 30px;
				}
			}
		}
	}
}
